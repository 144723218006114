import { fetchAdminTasks } from "@/api/admin";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { CalendarIcon, ChevronRightIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const Overview = () => {
  const { data: adminTasks, isLoading } = useQuery<any, Error>(
    "adminTasksForDashboardPreview",
    () => fetchAdminTasks(),
    {
      retry: false,
    }
  );

  return (
    <div className="flex flex-col gap-8 min-h-[600px]">
      {isLoading ? (
        <LoadingSpinner size="w-5 h-5" />
      ) : (
        <>
          <div className="bg-white col-span-1 shadow rounded py-3 px-5 ">
            <div className="flex justify-between ">
              <div className="font-semibold text-lg uppercase">All Tasks</div>
              <div className="text-indigo-600 hover:text-indigo-900 cursor-pointer font-medium">
                <Link to="/tasks">View All</Link>
              </div>
            </div>

            <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md mt-3">
              {adminTasks?.slice(0, 5)?.map((task: any, index: any) => (
                <li
                  key={index}
                  className="relative flex justify-between gap-x-6 px-4 py-5 sm:px-6 ">
                  <div className="flex gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        <a href={task.href}>
                          <span className="absolute inset-x-0 -top-px bottom-0" />
                          {task.title}
                        </a>
                      </p>
                      <p className="mt-5 flex text-xs leading-5 text-gray-500">
                        <UsersIcon className="h-5 w-5 flex-none text-gray-400 mr-1" />
                        {task?.user_first_name} {task.user_last_name} / {task.user_email}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-4">
                    <div className="hidden sm:flex sm:flex-col sm:items-end gap-1">
                      <p className="text-sm  text-gray-900 flex justify-end flex-col items-end">
                        <div>
                          {task.partner_first_name} {task.partner_last_name}
                        </div>
                        <div className="text-xs leading-5 text-gray-500">{task.partner_email}</div>
                      </p>
                      <p className="mt-1 text-sm leading-5 text-gray-500 flex gap-2">
                        {/* date include time */}
                        <CalendarIcon className="h-5 w-5 flex-none text-gray-400" />
                        Due date {new Date(task.due_date).toLocaleString()}
                      </p>
                    </div>
                    <ChevronRightIcon
                      className="h-5 w-5 flex-none text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/*  <div className="bg-white col-span-1 shadow rounded py-3 px-5 ">
            <div className="flex justify-between ">
              <div className="font-semibold text-lg uppercase">Waiting for approval strategies</div>
              <div className="text-indigo-600 hover:text-indigo-900 cursor-pointer font-medium">
                View All
              </div>
            </div>

            <div className="flow-root my-4">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-600 sm:pl-6 uppercase">
                            Task Name
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-normal text-gray-600 uppercase">
                            Partner Name
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {people.map((person) => (
                          <tr key={person.email}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {person.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.title}
                            </td>

                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a href="@#" className="text-indigo-600 hover:text-indigo-900">
                                Edit<span className="sr-only">, {person.name}</span>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default Overview;
