import { useQuery } from "react-query";
import { authMe } from "@/api/auth";
import { getToken, removeToken } from "@/utils/token";

import LoadingSpinner from "@/components/UI/LoadingSpinner";

import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const token = getToken();
  const [, setUser] = useRecoilState(userAtom);

  const { isLoading, data, isError, status } = useQuery<any, Error>("authMe", authMe, {
    retry: false,
  });

  if (isError) {
    removeToken();
    window.location.href = "/login";
  }

  useEffect(() => {
    if (status === "success") {
      if (data) {
        setUser(data.data);
      } else {
        removeToken();
        window.location.href = "/login";
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!token) {
    window.location.href = "/login";
  }

  return isLoading ? (
    <div className="bg-mainBackground h-screen flex justify-center items-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Outlet />
    </>
  );
};

export default PrivateRoute;
