import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import en from "./en";
import tr from "./tr";

const resources = {
  en: {
    translation: en,
  },
  tr: {
    translation: tr,
  },
};

const LanguageDetector = {
  type: "languageDetector",
  detect: () => {
    return "en";
  },
  init: Function.prototype,
  cacheUserLanguage: () => {},
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector as any)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
