import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { updateRoadmapStatus } from "@/api/admin";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export default function RejectDescriptionModal(
  props: React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    id: number;
    refetch: any;
  }>
) {
  const { open, setOpen, id, refetch } = props;

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { mutateAsync, isLoading } = useMutation(
    "updateRoadmapStatustoReject",
    updateRoadmapStatus
  );

  const handleTaskDetailModal = () => {
    setErrorMessage("");
    if (!message) return setErrorMessage("Please enter a message");
    mutateAsync({
      id: id as number,
      application_status: "rejected",
      message: "",
    })
      .then(() => {
        refetch();
        setOpen(false);
      })
      .finally(() => {});
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                      onClick={() => setOpen(false)}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900">
                        What’s wrong with this strategy?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          If you have a revise request write here what’s the problem on this
                          strategy.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col p-4 gap-2">
                    <label className="text-sm font-medium" htmlFor="fname">
                      About
                    </label>
                    <textarea
                      onChange={(e) => setMessage(e.target.value)}
                      className="border  rounded outline-none p-2"
                      rows={6}
                      id="fname"
                      name="fname"
                    />
                    {errorMessage && (
                      <div className="flex items-center gap-2">
                        <p className="text-sm text-red-500">{errorMessage}</p>
                      </div>
                    )}
                  </div>
                  <div className="px-4 pt-4 flex justify-end">
                    <DefaultButton isLoading={isLoading} onClick={handleTaskDetailModal}>
                      Send Revise
                    </DefaultButton>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
