import React from "react";
import { CalendarIcon, UsersIcon } from "@heroicons/react/20/solid";
import { fetchMainTasks } from "@/api/projects";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { fetchAdminTasks } from "@/api/admin";

const AllTasksForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: adminTasksAll, isLoading } = useQuery<any, Error>(
    "adminTasksAll1",
    () => fetchAdminTasks(id),
    {
      retry: false,
      enabled: !!id,
    }
  );

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner size="w-6 h-6 mt-8" />
      ) : (
        <div className="overflow-hidden bg-white shadow sm:rounded-md ">
          <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md ">
            {adminTasksAll?.map((task: any, index: any) => (
              <li
                key={index}
                onClick={() =>
                  navigate(`/tasks/${task?.project_id}`, {
                    state: {
                      project_story_id: task.project_story_id,
                      task_id: task.task_id,
                      customer_name: task?.user_first_name + " " + task?.user_last_name,
                      customer_email: task.user_email,
                    },
                  })
                }
                className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 cursor-pointer">
                <div className="flex gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      <a href={task.href}>
                        <span className="absolute inset-x-0 -top-px bottom-0" />
                        {task.title}
                      </a>
                    </p>
                    <p className="mt-5 flex text-xs leading-5 text-gray-500">
                      <UsersIcon className="h-5 w-5 flex-none text-gray-400 mr-1" />
                      {task?.user_first_name} {task.user_last_name} / {task.user_email}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-x-4">
                  <div className="hidden sm:flex sm:flex-col sm:items-end gap-1">
                    <p className="text-sm  text-gray-900 flex justify-end flex-col items-end">
                      <div>
                        {task.partner_first_name} {task.partner_last_name}
                      </div>
                      <div className="text-xs leading-5 text-gray-500">{task.partner_email}</div>
                    </p>
                    <p className="mt-1 text-sm leading-5 text-gray-500 flex gap-2">
                      <CalendarIcon className="h-5 w-5 flex-none text-gray-400" />
                      Due date {new Date(task.due_date).toLocaleString()}
                    </p>
                  </div>
                  <ChevronRightIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AllTasksForm;
