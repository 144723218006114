import ProjectTable from "@/features/projects/components/ProjectTable";
import React from "react";

const ProjectsPage = () => {


  return (
    <div>
      <div className="font-bold text-2xl mb-4">Projects</div>
      <ProjectTable />
    </div>
  );
};

export default ProjectsPage;
