import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import PrivateRoute from "./components/Routes/PrivateRoute";
import PageContainer from "./components/Layout/PageContainer";
import LoginPage from "./views/Auth/LoginPage";
import OverviewPage from "@/views/Dashboard/OverviewPage";
import "./index.css";
import ProjectsPage from "./views/Dashboard/ProjectsPage";
import PartnersPage from "./views/Dashboard/PartnersPage";
import PartnerDetailPage from "./views/Dashboard/PartnersPage/PartnerDetailPage";
import ProjectDetailsPage from "./views/Dashboard/ProjectsPage/ProjectDetailPage";
import PaymentsPage from "./views/Dashboard/PaymentsPage";
import StrategiesPage from "./views/Dashboard/StrategiesPage";
import AllTasksPage from "./views/Dashboard/AllTasksPage";
import TaskDetailPage from "./views/Dashboard/AllTasksPage/TaskDetailPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route element={<PageContainer />}>
            <Route index element={<OverviewPage />} />

            <Route path="projects">
              <Route index element={<ProjectsPage />} />
              <Route path=":id" element={<ProjectDetailsPage />} />
            </Route>

            <Route path="partners">
              <Route index element={<PartnersPage />} />
              <Route path=":id" element={<PartnerDetailPage />} />
            </Route>

            <Route path="payments" element={<PaymentsPage />} />
            <Route path="strategies" element={<StrategiesPage />} />

            <Route path="tasks">
              <Route index element={<AllTasksPage />} />
              {/*project id and task id */}
              <Route path=":project_id" element={<TaskDetailPage />} />
            </Route>
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="login" element={<LoginPage />}></Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
