// Package
import axios from "axios";
// Util
import { getToken } from "@/utils/token";
import i18next from "i18next";

const api = axios.create({
  baseURL: "https://dev.api.mikrolo.com/",
  headers: {
    "Accept-Language": i18next.language,
  },
});

api.interceptors.request.use((request: any) => {
  request.headers.common.Authorization = `Bearer ${getToken()}`;

  return request;
});

api.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 403) {
      localStorage.removeItem("adminToken");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
