import { fetchMainTasks } from "@/api/projects";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import Details from "@/features/tasks/components/Details";
import Message from "@/features/tasks/components/Message";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useParams } from "react-router-dom";

const pages = [
  { name: "All Tasks", href: "/tasks", current: false },
  { name: "Project Nero", href: "#", current: true },
];

const tabs: any = [
  { name: "Task Detail", id: "details", current: true },
  { name: "Messages", id: "message", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const TaskDetailPage = () => {
  const [activeTab, setActiveTab] = useState<"details" | "message" | "preview">("details");
  // get id from url
  const { project_id } = useParams();
  // get navigate state
  const {
    state: { project_story_id, task_id, customer_name, customer_email },
  } = useLocation();

  const { data: taskDetail, isLoading } = useQuery(
    "fetchMainTasks",
    () =>
      fetchMainTasks({
        project_id: project_id,
        project_story_id: project_story_id,
        task_id: task_id,
      }),
    {
      retry: false,
      enabled: !!project_id && !!project_story_id && !!task_id,
    }
  );

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner size="w-6 h-6" />
      ) : (
        <>
          <div className="font-bold text-2xl mb-4">All Tasks</div>
          <div>
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div>
                    <a href="1#" className="text-gray-400 hover:text-gray-500">
                      <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      <span className="sr-only">Home</span>
                    </a>
                  </div>
                </li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    to={"/tasks"}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    Tasks
                  </Link>
                </div>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    {taskDetail?.items[0].story_name}
                  </div>
                </div>
              </ol>
            </nav>

            <div className="bg-white p-4 my-5">
              <div className="font-medium ">Details</div>
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block border w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(event: any) => {
                      setActiveTab(event.target.value);
                    }}
                    defaultValue={tabs.find((tab: any) => tab.current).name}>
                    {tabs.map((tab: any) => (
                      <option value={tab.id} key={tab.name}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab: any) => (
                        <div
                          key={tab.name}
                          onClick={() => setActiveTab(tab.id)}
                          className={classNames(
                            tab.id === activeTab
                              ? "border-indigo-500 text-indigo-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                          )}>
                          {tab.name}
                        </div>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>

              <div>
                {activeTab === "details" && (
                  <Details
                    customer_name={customer_name}
                    customer_email={customer_email}
                    selectedStory={taskDetail?.items[0]}
                  />
                )}
              </div>
              <div>
                {activeTab === "message" && (
                  <Message selectedStory={taskDetail?.items[0]}></Message>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TaskDetailPage;
