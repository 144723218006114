const getToken = () => {
  return localStorage.getItem("adminToken");
};

const setToken = (token: string) => {
  localStorage.setItem("adminToken", token);
};

const removeToken = () => {
  localStorage.removeItem("adminToken");
};

export { getToken, setToken, removeToken };
