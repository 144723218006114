import { fetchAllPartners } from "@/api/partners";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

export default function PartnersTable() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery("fetchAllPartners", () => fetchAllPartners());

  return isLoading ? (
    <LoadingSpinner size="w-6 h-6" />
  ) : (
    <>
      <input
        className="w-2/3 mb-3 outline-none px-4 py-1.5 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 "
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="search"
        placeholder="Search"
      />
      <div className=" overflow-x-auto border">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                  Partner Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Email
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Tasks
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Roles
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                  <span className="sr-only">View</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {data
                ?.filter((d: any) => d.partner_name.toLowerCase().includes(search.toLowerCase()))
                ?.slice(search ? 0 : (page - 1) * 10, search ? data?.length : page * 10)
                ?.map((d: any) => (
                  <tr key={d.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                      {d.partner_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{d.email}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {d?.task_count}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {d?.partner_roles?.map((role: any) => role).join(", ")}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                      <Link
                        to={`/partners/${d.id}`}
                        className="text-indigo-600 hover:text-indigo-900">
                        View<span className="sr-only">, </span>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <div className="flex items-center">
          <div className="flex items-center">
            <button
              onClick={() => setPage(page - 1)}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              disabled={page === 1}>
              Previous
            </button>
            <button
              onClick={() => setPage(page + 1)}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              disabled={data?.length / 10 < page}>
              Next
            </button>
          </div>
        </div>
        <div className="flex items-center">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{page === 1 ? 1 : (page - 1) * 10 + 1}</span> to{" "}
            <span className="font-medium">{page === 1 ? 10 : (page - 1) * 10 + 10}</span> of{" "}
            <span className="font-medium">{Math.ceil(data?.length / 10)}</span> results
          </p>
        </div>
      </div>
    </>
  );
}
