import React from "react";

type Props = {
  title: string;
};

const ContentTitle = (props: Props) => {
  return (
    <div className="mb-4">
      <h1 className="text-2xl font-semibold text-gray-900">{props.title}</h1>
    </div>
  );
};

export default ContentTitle;
