const tr = {
  overview: "Genel Bakış",
  myProfile: "Profilim",
  payments: "Ödemeler",
  logout: "Çıkış",
  english: "İngilizce",
  turkish: "Türkçe",
  newRequest: "Yeni İstek",
  active: "Aktif",
  done: "Tamamlanmış",
  taskName: "Görev Adı",
  status: "Durum",
  dueDate: "Son teslim tarihi",
  clientName: "Müşteri Adı",
  action: "Eylem",
  tasks: "Görevler",
  workDetail: "İş Detayları",
  pending: "Beklemede",
  domainName: "Alan Adı",
  targetCountry: "Hedef Ülke",
  socialMediaAccounts: "Sosyal Medya Hesapları",
  keywords: "Anahtar Kelimeler",
  competitors: "Rakipler",
  clientNotes: "Müşteri Notları",
  clientOldWorks: "Müşterinin Eski Çalışmaları",
  deny: "Reddet",
  accept: "Kabul",
  taskConfirmed: "İş Onaylandı",
  information: "Bilgi",
  activities: "Hareketler",
  personalDetails: "Kişisel Bilgiler",
  jobTitle: "İş Ünvanı",
  role: "Rol",
  finishedProjects: "Tamamlanmış Projeler",
  pendingProjects: "Aktif Projeler",
  unfinishedProjects: "Tamamlanmamış Projeler",
  designer: "Tasarımcı",
  copywriter: "Metin Yazarı",
  workExperience: "Çalışma Deneyimi",
  email: "E-mail",
  password: "Parola",
  forgotYourPassword: "Şifreninizi mi unuttunuz?",
  dontHaveAnAccount: "Hesabınız yok mu?",
  registerNow: "Şimdi kayıt ol",
  accountDetails: "Hesap Detayları",
  profilePhotoEdit: "Profil Fotoğrafını Düzenle",
  chooseAFile: "Bir dosya seç",
  editWorkExperince: "Çalışma Deneyimi Düzenle",
  companyName: "Şirket İsmi",
  startDate: "Başlangıç Tarihi",
  endDate: "Bitiş Tarihi",
  save: "Kaydet",
  cancel: "İptal",
  review: "İncelemede",
  enterYourJobTitle: "İş Ünvanınızı girin",
  enterYourBio: "Hakkınızda bir şeyler yazın",
  sortByStatus: "Duruma göre sırala",
  sortByDate: "Tarihe göre sırala",
  comments: "Yorumlar",
  sendComment: "Yorum Gönder",
  enterComment: "Yorumunuzu girin",
  dragAndDrop: "Dosyalarınızı sürükleyip bırakın ya da",
  browse: "Masaüstünden seçin",
  welcome: "Hoşgeldiniz",
  to: " ",
  enterYourEmail: "E-mail adresinizi girin",
  login: "Giriş",
  signup: "Kayıt Ol",
  fullname: "Ad Soyad",
  enterYourFullName: "Adınızı ve Soyadınızı girin",
  phone: "Telefon",
  enterYourPhone: "Telefon numaranızı girin",
  enterYourPassword: "Parolanızı girin",
  alreadyHaveAnAccount: "Zaten bir hesabınız var mı?",
  backToTasks: "Görevlere dön",
  unreadMessage: "Okunmamış Mesaj",
  unacceptedProjects: "Kabul Edilmemiş Projeler",
  dashboard: "Anasayfa",
  projects: "Projeler",
  allTasks: "Tüm Görevler",
  profile: "Profil",
  signOut: "Çıkış Yap",
  "Works to do": "Yapılacak İşler",
  detail: "Detay",
  search: "Ara",
  clientInformation: "Müşteri Bilgileri",
  taskDetails: "Görev Detayları",
  "companyName/ClientName": "Şirket İsmi/Müşteri İsmi",
  label: "Etiket",
  "View All": "Tümünü Gör",
  "To Do": "Yapılacak",
  "In Review": "İncelemede",
  partners: "Ortaklar",
  strategies: "Stratejiler",
};

export default tr;
