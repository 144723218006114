import { fetchAllPartners } from "@/api/partners";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

interface Props {
  data: any;
}

export default function PartnersForm(props: Props) {
  const { data: partnersInfo } = props;
  /*   const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false); */

  /*   const partnersIds = Object.values(partnersInfo?.partners || []);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (partnersIds.length === 0) return setData([]);
      else {
        partnersIds.map(async (d: any) => {
          const incomingData = await fetchAllPartners(d);
          setData((prev: any) => [...prev, incomingData]);
        });
      }
    };
    getData();
  }, []); */

  // Cannot convert undefined or null to object
  // TypeError: Cannot convert undefined or null to object
  console.log("Object.values(partnersInfo?.partners).at(0)", partnersInfo.partners);

  const { data, isLoading } = useQuery(
    "partners",
    () =>
      fetchAllPartners(partnersInfo?.partners ? Object.values(partnersInfo?.partners).at(0) : 0),
    {
      enabled: !!partnersInfo?.partners,
    }
  );

  return (
    <>
      {isLoading ? (
        <LoadingSpinner size="w-6 h-6 mt-5" />
      ) : (
        <>
          <div className="flex gap-5">
            <div className=" overflow-x-auto border w-full">
              <div className="inline-block   w-full">
                <table className=" divide-y divide-gray-300 w-full">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                        Partner Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Tasks
                      </th>

                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data?.map((d: any) => (
                      <tr key={d.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {d.first_name} {d.last_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {d.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {d?.task_count}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <Link
                            to={`/partners/${d.id}`}
                            className="text-indigo-600 hover:text-indigo-900">
                            View<span className="sr-only">, </span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
