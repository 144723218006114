import client from "./client";

export const fetchRoadmaps = async (data: { application_status: "approved" | "draft" }) => {
  const response = await client.get(`/admin/roadmap`, {
    params: {
      application_status: data.application_status,
    },
  });
  return response.data;
};

export const updateRoadmapStatus = async (data: {
  id: number;
  application_status: "approved" | "rejected" | "draft";
  message?: string;
}) => {
  const response = await client.put(`/admin/roadmap`, {
    id: data.id,
    status: data.application_status,
    message: data.message,
  });
  return response.data;
};

export const fetchAdminTasks = async (project_id?: any) => {
  const response = await client.get(`/admin/task/multiple`, {
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};
