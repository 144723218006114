import { fetchMultiTimeline } from "@/api/stories";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useQuery } from "react-query";
import humanizeDuration from "humanize-duration";
import moment from "moment";

type Props = {
  selectedStory: any;
};

const Message = (props: Props) => {
  const { selectedStory } = props;

  const { data, isLoading } = useQuery(
    "fetchMultiTimeline",
    () => fetchMultiTimeline(selectedStory.project_story_id),
    {
      enabled: !!selectedStory.project_story_id,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  console.log("data", data);

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full  w-full">
          <LoadingSpinner size="w-5 h-5" />
        </div>
      ) : (
        <div className="flex flex-col  h-full">
          <div className="text-xl font-medium my-5">Comments with talents</div>
          <div className="overflow-auto h-[calc(100vh-350px)]">
            <div className="flex flex-col-reverse gap-3">
              {data.map((item: any) => {
                const currentTask = selectedStory?.tasks?.find(
                  (e: any) => e.partner_task_id === item?.partner_task_id
                );
                return (
                  <>
                    <div className="flow-root">
                      <ul className="-mb-8 pr-2">
                        <li key={item.id}>
                          <div className="relative  pb-8">
                            <div className="relative bg-green-50 flex items-start space-x-3">
                              <>
                                <div className="relative">
                                  <img
                                    className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                    src="/assets/icons/placeholder.png"
                                    alt=""
                                  />
                                  <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                    <ChatBubbleLeftEllipsisIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </div>
                                <div className="min-w-0 flex-1">
                                  <div>
                                    <div className="text-sm">
                                      <span className="font-medium text-gray-900">
                                        {item?.content?.type !== "message"
                                          ? currentTask?.partner_first_name
                                          : "Customer"}
                                      </span>
                                    </div>
                                    <p className="mt-0.5 text-sm text-gray-500">
                                      Commented{" "}
                                      {humanizeDuration(moment().diff(moment(item?.create_date)), {
                                        largest: 1,
                                        language: "en",
                                      })}
                                    </p>
                                  </div>
                                  <div className="mt-2 text-sm text-gray-700">
                                    <p>{item?.content?.user_message || item?.content?.message}</p>
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </li>
                        {/* {item?.user_id === -1 && item?.content?.type !== "notification" ? (
                          <li key={item.id}>
                            <div className="relative  pb-8">
                              <div className="relative bg-green-50 flex items-start space-x-3">
                                <>
                                  <div className="relative">
                                    <img
                                      className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                      src="/assets/icons/placeholder.png"
                                      alt=""
                                    />
                                    <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                      <ChatBubbleLeftEllipsisIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span className="font-medium text-gray-900">Customer</span>
                                      </div>
                                      <p className="mt-0.5 text-sm text-gray-500">
                                        Commented{" "}
                                        {humanizeDuration(
                                          moment().diff(moment(item?.create_date)),
                                          {
                                            largest: 1,
                                            language: "en",
                                          }
                                        )}
                                      </p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">
                                      <p>{item?.content?.user_message || item?.content?.message}</p>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>
                        ) : (
                          item?.content?.type !== "notification" &&
                          item?.content?.type !== "work" && (
                            <>
                              <li key={item.id}>
                                <div className="relative pb-8">
                                  <div className="relative flex items-start space-x-3">
                                    <>
                                      <div className="relative">
                                        {currentTask?.partner_avatar ? (
                                          <img
                                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                            src={currentTask?.partner_avatar}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                            src="/assets/icons/placeholder.png"
                                            alt=""
                                          />
                                        )}

                                        <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                          <ChatBubbleLeftEllipsisIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </div>
                                      <div className="min-w-0 flex-1">
                                        <div>
                                          <div className="text-sm">
                                            <span className="font-medium text-gray-900">
                                              {currentTask?.partner_first_name}
                                            </span>
                                          </div>
                                          <p className="mt-0.5 text-sm text-gray-500">
                                            Commented{" "}
                                            {humanizeDuration(
                                              moment().diff(moment(item?.create_date)),
                                              {
                                                largest: 1,
                                                language: "en",
                                              }
                                            )}
                                          </p>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>
                                            {item?.content?.user_message || item?.content?.message}
                                          </p>
                                          {item?.content?.files?.length > 0 && (
                                            <>
                                              <span className="font-bold">Files: </span>
                                              {item?.content?.files?.map(
                                                (file: any, index: any) => (
                                                  <>
                                                    <a
                                                      href={file?.file_url}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      className="text-blue-500 hover:text-blue-600">
                                                      {file?.file_name}
                                                    </a>
                                                    {item?.content?.files?.length <= index && (
                                                      <span className="mx-1">|</span>
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        )}
                        {item?.content?.type === "notification" && (
                          <>
                            <li key={item.id}>
                              <div className="relative pb-8 w-full flex justify-center">
                                <div className="mt-2  text-sm text-gray-700  bg-gray-100 w-fit p-2 rounded">
                                  <p>{item?.content?.user_message || item?.content?.message}</p>
                                </div>
                              </div>
                            </li>
                          </>
                        )} */}
                        {item?.content?.type === "work" && (
                          <li key={item.id}>
                            <div
                              className={`relative ${
                                data
                                  .reduce((acc: any, x: any) => {
                                    if (x?.content?.type === "work") {
                                      if (
                                        !acc.find(
                                          (i: any) => i?.partner_task_id === x.partner_task_id
                                        )
                                      )
                                        acc.push(x);
                                    }
                                    return acc;
                                  }, [])
                                  .find((i: any) => i?.id === item?.id)
                                  ? "border border-gray-200 p-1 pb-3 shadow mb-8 rounded"
                                  : "pb-8"
                              }`}>
                              <div className="relative flex items-start space-x-3">
                                <>
                                  <div className="relative">
                                    {currentTask?.partner_avatar ? (
                                      <img
                                        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                        src={currentTask?.partner_avatar}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                        src="/assets/icons/placeholder.png"
                                        alt=""
                                      />
                                    )}

                                    <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                      <ChatBubbleLeftEllipsisIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span className="font-medium text-gray-900">
                                          {currentTask?.partner_first_name}
                                        </span>
                                      </div>
                                      <p className="mt-0.5 text-sm text-gray-500">
                                        Commented{" "}
                                        {humanizeDuration(
                                          moment().diff(moment(item?.create_date)),
                                          {
                                            largest: 1,
                                            language: "en",
                                          }
                                        )}
                                      </p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">
                                      <p>
                                        <span className="font-bold">Text: </span>
                                        <span>
                                          {item?.content?.user_message || item?.content?.message}
                                        </span>
                                      </p>
                                      {item?.content?.files?.length > 0 && (
                                        <>
                                          <span className="font-bold">Files: </span>
                                          {item?.content?.files?.map((file: any, index: any) => (
                                            <>
                                              <a
                                                href={file?.file_url}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-blue-500 hover:text-blue-600">
                                                {file?.file_name}
                                              </a>
                                              {item?.content?.files?.length <= index && (
                                                <span className="mx-1">|</span>
                                              )}
                                            </>
                                          ))}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
