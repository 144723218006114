import PartnersTable from "@/features/partners/components/PartnersTable";
import React from "react";

const PartnersPage = () => {
  return (
    <div>
      <div className="font-bold text-2xl mb-4">Partners</div>
      <PartnersTable />
    </div>
  );
};

export default PartnersPage;
