import { RegisterFormDataType } from "../models/user.interface";
import client from "./client";

interface ILogin {
  email: string;
  password: string;
}

export const loginUser = async (loginInfo: ILogin) => {
  const form_data = new FormData();

  form_data.append("username", loginInfo.email);
  form_data.append("password", loginInfo.password);

  const user = await client.post("admin/auth/login", form_data);
  return user;
};

export const authMe = async () => {
  const data = await client.get("admin/auth/me");
  return data.data;
};

export const puthAuthMe = async (data: {
  userId: number;
  email?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  country: number;
  tax_identification_number: string;
  tax_office: string;
  imageFile?: File | null;
  invoice_title: string;
  invoice_type: string;
}) => {
  // create multipart form data
  const form_data: any = new FormData();

  if (data.email) form_data.append("email", data.email);
  if (data.phone_number) form_data.append("phone_number", data.phone_number);
  if (data.first_name) form_data.append("first_name", data.first_name);
  if (data.last_name) form_data.append("last_name", data.last_name);
  if (data.address) form_data.append("address", data.address);
  if (data.city) form_data.append("city", data.city);
  if (data.state) form_data.append("state", data.state);
  if (data.zip_code) form_data.append("zip_code", data.zip_code);
  if (data.country) form_data.append("country", data.country);
  if (data.tax_identification_number)
    form_data.append("tax_identification_number", data.tax_identification_number);
  if (data.tax_office) form_data.append("tax_office", data.tax_office);
  if (data.invoice_title) form_data.append("invoice_title", data.invoice_title);
  if (data.invoice_type) form_data.append("invoice_type", data.invoice_type);

  if (data.tax_identification_number)
    form_data.append("tax_identification_number", data.tax_identification_number);
  if (data.tax_office) form_data.append("tax_office", data.tax_office);

  if (data.imageFile) form_data.append("avatar", data.imageFile);

  const res = await client.put(`/auth/me/${data.userId}`, form_data);
  return res;
};
