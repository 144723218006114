import React from "react";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";

const tabs = [
  { name: "All payments", href: "#", current: true },
  { name: "Partners payments", href: "#", current: false },
  { name: "My expenses", href: "#", current: false },
  { name: "Earnings", href: "#", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const stats = [
  { name: "Total Earnings", stat: "71,897" },
  { name: "Sales", stat: "58.16" },
  { name: "Expenses", stat: "24.57" },
];

const people = [
  {
    name: "Jane Cooper",
    date: "11.11.2020 - 11.12.2020",
    money: "3000TL",
  },
  {
    name: "Jane Cooper",
    date: "11.11.2020 - 11.12.2020",
    money: "3000TL",
  },
  {
    name: "Jane Cooper",
    date: "11.11.2020 - 11.12.2020",
    money: "3000TL",
  },
  {
    name: "Jane Cooper",
    date: "11.11.2020 - 11.12.2020",
    money: "3000TL",
  },
  {
    name: "Jane Cooper",
    date: "11.11.2020 - 11.12.2020",
    money: "3000TL",
  },
  {
    name: "Jane Cooper",
    date: "11.11.2020 - 11.12.2020",
    money: "3000TL",
  },
  // More people...
];

const PaymentsPage = () => {
  return (
    <div>
      <div className="font-bold text-2xl">Payments</div>
      <div className="mt-6">
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3>
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.name}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {item.stat}
                </dd>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="mt-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Details</h3>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                    )}
                    aria-current={tab.current ? "page" : undefined}>
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>

          <div>
            <input
              type="search"
              placeholder="Search"
              className="border border-gray-300 rounded-md px-2 py-1 mt-5 w-2/3"
            />
          </div>
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-5">
            {people.map((person) => (
              <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">{person.name}</h3>
                    </div>
                    <p className="mt-1 truncate text-sm text-gray-500">{person.date}</p>
                    <p className="mt-1 truncate text-sm text-black font-medium">{person.money}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PaymentsPage;
