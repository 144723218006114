import { fetchRoadmaps } from "@/api/admin";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DetailModal from "@/features/strategies/DetailModal";
import React, { useState } from "react";
import { useQuery } from "react-query";

const tabs = [
  { name: "Waiting for approval", href: "#", current: true },
  { name: "Active", href: "#", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const people = [
  {
    name: "Partner name",
    title: "Strategy name",
    status: "Waiting for approval",
  },
  {
    name: "Partner name",
    title: "Strategy name",
    status: "Waiting for approval",
  },
  {
    name: "Partner name",
    title: "Strategy name",
    status: "Waiting for approval",
  },
];

const StrategiesPage = () => {
  const [status, setStatus] = useState<"Waiting for approval" | "Active">("Waiting for approval");
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const {
    data: roadmap,
    isLoading: isLoadingRoadmap,
    isFetching,
    refetch,
  } = useQuery(["roadmap", status], () =>
    fetchRoadmaps({
      application_status: status === "Waiting for approval" ? "draft" : "approved",
    })
  );

  return (
    <div>
      <div className="font-bold text-2xl">Strategies </div>
      <div>
        <div className="sm:hidden my-2 ">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            onChange={(event) => setStatus(event.target.value as any)}
            className="block py-2 w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            {tabs.map((tab) => (
              <option value={tab.name} key={tab.name}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden sm:block mt-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs.map((tab) => (
                <div
                  key={tab.name}
                  onClick={() => setStatus(tab.name as any)}
                  className={classNames(
                    tab.name === status
                      ? "bg-blue-100  "
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "w-1/2 border-b-2 py-5 text-xs font-medium uppercase px-4 cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}>
                  {tab.name}
                </div>
              ))}
            </nav>
          </div>
        </div>

        <div className="mt-4">
          <input
            type="search"
            className="w-11/12  bg-white h-9 px-5 rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {isLoadingRoadmap || isFetching ? (
          <LoadingSpinner size="w-6 h-6 mt-5" />
        ) : (
          <>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Status
                          </th>

                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {roadmap
                          ?.filter(
                            (d: any) =>
                              d.name.toLowerCase().includes(search.toLowerCase()) ||
                              d.name.toLowerCase().includes(search.toLowerCase())
                          )
                          ?.slice(
                            search ? 0 : (page - 1) * 10,
                            search ? roadmap?.length : page * 10
                          )
                          ?.map((r: any, index: any) => (
                            <tr key={index}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {r.partner_name || "Mikrolo"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {r.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 ">
                                <div
                                  className={`
                                ${
                                  r.application_status === "approved"
                                    ? "text-green-800 bg-green-100"
                                    : "text-yellow-800 bg-yellow-100"
                                }
                                 w-fit py-1 px-2 text-xs rounded-full font-medium`}>
                                  {r.application_status.charAt(0).toUpperCase() +
                                    r.application_status.slice(1)}
                                </div>
                              </td>

                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <button
                                  onClick={() => {
                                    setSelectedData(r);
                                    setOpen(true);
                                  }}
                                  className="text-indigo-600 hover:text-indigo-900">
                                  View
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <div className="flex items-center">
                  <button
                    onClick={() => setPage(page - 1)}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    disabled={page === 1}>
                    Previous
                  </button>
                  <button
                    onClick={() => setPage(page + 1)}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    disabled={roadmap?.length / 10 < page}>
                    Next
                  </button>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">{page === 1 ? 1 : (page - 1) * 10 + 1}</span> to{" "}
                  <span className="font-medium">{page === 1 ? 10 : (page - 1) * 10 + 10}</span> of{" "}
                  <span className="font-medium">{Math.ceil(roadmap?.length / 10)}</span> results
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      {open && <DetailModal refetch={refetch} data={selectedData} open={open} setOpen={setOpen} />}
    </div>
  );
};

export default StrategiesPage;
