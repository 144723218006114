import client from "./client";

export const fetchProjects = async (id?: any) => {
  const response = await client.get(`/admin/project`, {
    params: {
      project_id: id,
    },
  });
  return response.data;
};

export const fetchMainTasks = async (data: {
  project_id: any;
  project_story_id?: any;
  task_id?: any;
  status?: string;
}) => {
  const response = await client.get(`/project/${data.project_id}/main-tasks`, {
    params: {
      project_story_id: data?.project_story_id,
      task_id: data?.task_id,
      status: data?.status,
    },
  });
  return response.data;
};
