import React from "react";

type Props = {
  selectedStory: any;
  customer_name: string;
  customer_email: string;
};

const Details = (props: Props) => {
  const { selectedStory, customer_email, customer_name } = props;

  return (
    <div>
      <div className="w-full h-[calc(100%-100px)]  overflow-auto">
        <div className="text-sm text-gray-400 pt-6 first-letter:uppercase">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
            <div className="flex flex-col gap-1 capitalize">
              <div className="min-w-[60px] font-medium">Customer Name</div>
              <div className=" flex items-center text-black">{customer_name}</div>
            </div>
            <div className="flex flex-col gap-1 capitalize">
              <div className="min-w-[60px] font-medium">Customer Email</div>
              <div className=" flex items-center text-black">{customer_email}</div>
            </div>
            <div className="flex flex-col gap-1 capitalize">
              <div className="min-w-[60px] font-medium">status</div>
              <div className=" flex items-center text-black">
                {selectedStory?.project_story_status}
              </div>
            </div>
            <div className="flex flex-col gap-1 capitalize">
              <div className="min-w-[60px] font-medium">label</div>
              <div className={`rounded-full flex gap-2 text-black`}>
                {selectedStory?.label_title}
              </div>
            </div>

            <div className="flex flex-col gap-1 capitalize">
              <div className="min-w-[60px] font-medium">Due Date</div>

              <div className="text-black">
                {selectedStory?.due_date === null ? (
                  <div className="text-gray-500">No Due Date</div>
                ) : (
                  <div>{new Date(selectedStory?.due_date).toLocaleString()}</div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1  capitalize">
              <div className="min-w-[60px] font-medium">Assignee</div>
              <div className="flex items-center gap-2 text-black">
                {/* {
                  new Set(
                    selectedStory?.tasks?.map((item: any) => item?.partner_first_name?.trim())
                  )
                } */}
              </div>
            </div>
          </div>
        </div>
        <div>
          {selectedStory && (
            <div className="mt-2">
              {selectedStory.sub_content && (
                <>
                  <div className="mt-5 text-gray-400 text-sm font-medium">Brief</div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedStory.sub_content,
                    }}
                  />
                </>
              )}
            </div>
          )}
        </div>
        <div>
          {selectedStory && (
            <>
              <div className="mt-2">
                {selectedStory.story_content && (
                  <>
                    <div className="mt-5 text-gray-400 text-sm font-medium">About</div>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedStory.story_description,
                      }}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
