import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// Component
import Input from "@/components/UI/Input";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import PopupModal from "@/components/PopupModal/PopupModal";
import ToastNotify from "@/components/UI/ToastNotify";

// Hook
import { setToken } from "@/utils/token";
import { loginUser } from "@/api/auth";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language);

  // State
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isForgetPassowrd, setIsForgetPassword] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  // Hook
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const navigate = useNavigate();

  const { mutate, isLoading: isLoadingPost } = useMutation(loginUser, {
    onSuccess: (data) => {
      setToken(data.data.access_token);
      navigate("/");
    },
    onError: (error: any) => {
      setError(error.response.data.detail);
    },
    retry: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(undefined);
    mutate({
      email,
      password,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("language", event.target.value);
  };

  return (
    <div
      className="h-screen lg:flex lg:justify-center lg:items-center relative "
      data-testid="login-page">
      <PopupModal closeAction={() => setIsForgetPassword(false)} isPopup={isForgetPassowrd}>
        <div className="flex flex-col gap-10 ">
          <p>
            Enter the email address you used to join and we'll send you instructions to reset your
            password. <br />
            <br />
            For security reasons, we do NOT store your password. Therefore, you can rest assured
            that we will never send your password via email.
          </p>
          <Input
            placeholder="E-mailinizi giriniz"
            name="email"
            title="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            required={true}
          />
          {error && <span className="text-dangerButtonPrimary text-sm -my-2">{error}</span>}
        </div>
      </PopupModal>
      <div className="flex flex-col lg:w-[460px] w-full relative">
        <form
          className="flex flex-col gap-5 col-span-1 p-[30px] bg-mainBackground lg:rounded-[50px] h-screen lg:h-fit"
          onSubmit={(e) => handleSubmit(e)}>
          <div className="mb-8">
            <div className="text-[42px] font-medium text-center">{t("welcome")}</div>
            <div className="text-center text-xl my-1.5">{t("to")}</div>
            <div className="w-full flex justify-center">
              <img src="/assets/logos/mikrolo-purple-dot.svg" alt="" width={218} />
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <Input
              placeholder={t("enterYourEmail")}
              name="email"
              title="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required={true}
            />
            <div className="flex flex-col gap-2 items-end">
              <Input
                placeholder={t("enterYourPassword")}
                name="password"
                title="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                required={true}
              />
            </div>
          </div>
          {!isForgetPassowrd && error && (
            <span className="text-dangerButtonPrimary text-sm -my-2">{error}</span>
          )}
          <div className="mt-5 w-full flex justify-center">
            <DefaultButton
              className="w-full"
              isDisabled={password.length === 0 || email.length === 0}
              isLoading={isLoadingPost}>
              {t("login")}
            </DefaultButton>
          </div>
        </form>
      </div>
      <ToastNotify />
    </div>
  );
};

export default LoginPage;
