import Breadcrumb from "@/components/Breadcrumb";
import ContentTitle from "@/components/ContenTitle";
import ProfileForm from "@/features/projects/components/ProfileForm";
import { useState } from "react";
import AllTasksForm from "@/features/projects/components/AllTasksForm";
import PartnersForm from "@/features/projects/components/PartnersForm";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchProjects } from "@/api/projects";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

type Props = {};

const pages = [{ name: "Projects", href: "/projects", current: false }];

const tabs = [
  { name: "Profile", href: "#", current: true },
  { name: "All Tasks", href: "#", current: false },
  { name: "Partners", href: "#", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const ProjectDetailsPage = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].name);

  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useQuery(["fetchprojectforDetail", id], () => fetchProjects(id), {
    enabled: !!id,
  });

  return (
    <div>
      <ContentTitle title="Project Detail" />
      <Breadcrumb pages={pages} />

      {isLoading ? (
        <LoadingSpinner size="w-6 h-6 mt-8" />
      ) : (
        <div className="mt-4 flex gap-8 ">
          <div className="hidden md:block  ">
            <div className="text-lg font-semibold text-gray-900">
              {data?.personal_information?.first_name} {data?.personal_information?.last_name}
            </div>
            <img
              className="w-48 h-40 rounded-full mt-5 object-cover border"
              src={data?.personal_information?.avatar || "/assets/img/avatar.png"}
              alt="partner"
            />
          </div>
          <div className="w-full">
            <div>
              <div className="font-medium text-gray-900 text-lg">Detail</div>
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    value={selectedTab}
                    onChange={(e) => setSelectedTab(e.target.value)}
                    className="block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    {tabs.map((tab) => (
                      <option value={tab.name} key={tab.name}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <div
                          key={tab.name}
                          onClick={() => setSelectedTab(tab.name)}
                          className={classNames(
                            selectedTab === tab.name
                              ? "border-indigo-500 text-indigo-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                          )}>
                          {tab.name}
                        </div>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {selectedTab === "Profile" && <ProfileForm data={data} />}
              {selectedTab === "All Tasks" && <AllTasksForm />}
              {selectedTab === "Partners" && <PartnersForm data={data} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetailsPage;
