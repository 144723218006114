import { fetchAdminTasks } from "@/api/admin";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import {
  BarsArrowUpIcon,
  CalendarIcon,
  ChevronRightIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export default function AllTasksPage() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [sort, setSort] = useState("asc");
  const [date, setDate] = useState("");

  const {
    data: adminTasks,
    isLoading,
    isError,
    error,
  } = useQuery<any, Error>("adminTasks", () => fetchAdminTasks(), {
    retry: false,
  });

  return (
    <>
      <div className="font-bold text-2xl mb-4">All Tasks</div>
      <div className="">
        {isLoading ? (
          <LoadingSpinner size="w-6 h-6" />
        ) : isError ? (
          <p>An error has occurred: {error?.message}</p>
        ) : (
          <div className="space-y-4">
            <div>
              <input
                type="search"
                className="w-11/12 border bg-white h-9 px-5 rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="flex flex-col sm:flex-row justify-between items-center gap-5">
              <div>
                <div className="mt-2 flex rounded-md shadow-sm">
                  <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <input
                      type="date"
                      onChange={(e) => {
                        setDate(e.target.value);
                        setPage(1);
                      }}
                      className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-4 pr-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      sort === "asc" ? setSort("desc") : setSort("asc");
                    }}
                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <BarsArrowUpIcon
                      className={`-ml-0.5 h-5 w-5 text-gray-400 transition-all
                      ${sort === "asc" ? "transform rotate-180" : ""}
                      `}
                      aria-hidden="true"
                    />
                    Sort by Create Date
                  </button>
                </div>
              </div>
              <div>
                <span className="inline-flex rounded-md shadow-sm">
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value);
                      setPage(1);
                    }}
                    id="message-type"
                    name="message-type"
                    className="outline-none font-medium -ml-px block w-full rounded-md border-0 bg-white py-2.5 pl-4 pr-9 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6
                    ">
                    <option value="">All</option>
                    <option value="assigned">Assigned</option>
                    <option value="finished">Finished</option>
                  </select>
                </span>
              </div>
            </div>

            <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md mt-3">
              {adminTasks
                ?.sort((a: any, b: any) => {
                  const dateA = new Date(a.create_date);
                  const dateB = new Date(b.create_date);
                  if (sort === "asc") return +dateB - +dateA;
                  return +dateA - +dateB;
                })
                ?.filter((d: any) =>
                  date
                    ? new Date(d.create_date).toLocaleDateString() ===
                      new Date(date).toLocaleDateString()
                    : true
                )
                ?.filter((d: any) => (status ? d.status === status : d.status))
                ?.filter((d: any) => d.title.toLowerCase().includes(search.toLowerCase()))
                ?.slice(search ? 0 : (page - 1) * 5, search ? adminTasks?.length : page * 5)
                ?.map((task: any, index: any) => (
                  <li
                    key={index}
                    onClick={() =>
                      navigate(`/tasks/${task.project_id}`, {
                        state: {
                          project_story_id: task.project_story_id,
                          task_id: task.task_id,
                          customer_name: task?.user_first_name + " " + task?.user_last_name,
                          customer_email: task.user_email,
                        },
                      })
                    }
                    className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 cursor-pointer">
                    <div className="flex gap-x-4">
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          <a href={task.href}>
                            <span className="absolute inset-x-0 -top-px bottom-0" />
                            {task.title}
                          </a>
                        </p>
                        <p className="mt-5 flex text-xs leading-5 text-gray-500">
                          <UsersIcon className="h-5 w-5 flex-none text-gray-400 mr-1" />
                          {task?.user_first_name} {task.user_last_name} / {task.user_email}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-x-4">
                      <div className="hidden sm:flex sm:flex-col sm:items-end gap-1">
                        <p className="text-sm  text-gray-900 flex justify-end flex-col items-end">
                          <div>
                            {task.partner_first_name} {task.partner_last_name}
                          </div>
                          <div className="text-xs leading-5 text-gray-500">
                            {task.partner_email}
                          </div>
                        </p>
                        <p className="mt-1 text-sm leading-5 text-gray-500 flex gap-2">
                          {/* date include time */}
                          <CalendarIcon className="h-5 w-5 flex-none text-gray-400" />
                          Due date {new Date(task.due_date).toLocaleString()}
                        </p>
                      </div>
                      <ChevronRightIcon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                ))}
            </ul>

            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <div className="flex items-center">
                  <button
                    onClick={() => setPage(page - 1)}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    disabled={page === 1}>
                    Previous
                  </button>
                  <button
                    onClick={() => setPage(page + 1)}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    disabled={
                      adminTasks
                        ?.filter((d: any) =>
                          date
                            ? new Date(d.create_date).toLocaleDateString() ===
                              new Date(date).toLocaleDateString()
                            : true
                        )
                        ?.filter((d: any) => (status ? d.status === status : d.status))
                        ?.filter((d: any) => d.title.toLowerCase().includes(search.toLowerCase()))
                        ?.length /
                        5 <=
                      page
                    }>
                    Next
                  </button>
                  <div className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white">
                    <span className="font-medium">{page}</span>
                    <span className="sr-only">of</span>
                    <span className="sr-only">pages</span>
                    <ChevronDownIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                    <select
                      className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer"
                      name="pagination"
                      aria-label="Pagination"
                      value={page}
                      onChange={(e) => setPage(Number(e.target.value))}>
                      {Array.from(
                        {
                          length: Math.ceil(
                            adminTasks
                              ?.filter((d: any) =>
                                date
                                  ? new Date(d.create_date).toLocaleDateString() ===
                                    new Date(date).toLocaleDateString()
                                  : true
                              )
                              ?.filter((d: any) => (status ? d.status === status : d.status))
                              ?.filter((d: any) =>
                                d.title.toLowerCase().includes(search.toLowerCase())
                              )?.length / 5
                          ),
                        },
                        (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(page - 1) * 5 + 1} </span>
                  to{" "}
                  <span className="font-medium">
                    {adminTasks
                      ?.filter((d: any) =>
                        date
                          ? new Date(d.create_date).toLocaleDateString() ===
                            new Date(date).toLocaleDateString()
                          : true
                      )
                      ?.filter((d: any) => (status ? d.status === status : d.status))
                      ?.filter((d: any) => d.title.toLowerCase().includes(search.toLowerCase()))
                      ?.length <
                    page * 5
                      ? adminTasks
                          ?.filter((d: any) =>
                            date
                              ? new Date(d.create_date).toLocaleDateString() ===
                                new Date(date).toLocaleDateString()
                              : true
                          )
                          ?.filter((d: any) => (status ? d.status === status : d.status))
                          ?.filter((d: any) => d.title.toLowerCase().includes(search.toLowerCase()))
                          ?.length
                      : page * 5}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium">
                    {
                      adminTasks
                        ?.filter((d: any) =>
                          date
                            ? new Date(d.create_date).toLocaleDateString() ===
                              new Date(date).toLocaleDateString()
                            : true
                        )
                        ?.filter((d: any) => (status ? d.status === status : d.status))
                        ?.filter((d: any) => d.title.toLowerCase().includes(search.toLowerCase()))
                        ?.length
                    }
                  </span>{" "}
                  results
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
